import React from 'react'
import { StyledThankYou } from './styles'

const ThankYou = () => {
    const goBack = () => window.history.back();
    return (
        <StyledThankYou>
            <svg width="70px" height="70px" class="success" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-1233.000000, -901.000000)" stroke="#34D183" stroke-width="4">
                        <g transform="translate(1237.000000, 905.000000)" class="path circle">
                            <circle class="path circle" cx="32" cy="32" r="32"></circle>
                            <polyline class="path check" points="48 22.7096774 26.6484279 42.3225806 15.483871 31.5557034">
                            </polyline>
                        </g>
                    </g>
                </g>
            </svg>
            <h1>Thank You!</h1>
            <p>Your form was submitted successfully.</p>
            <p>
                <button onClick={goBack}>
                    <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Thank-you" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="thank-you" transform="translate(-634.000000, -358.000000)" fill="#151515" fill-rule="nonzero">
                                <g transform="translate(634.000000, 358.000000)">
                                    <path d="M5.58916667,0.244166667 C5.26375012,-0.0811516153 4.73624988,-0.0811516153 4.41083333,0.244166667 L0.244166667,4.41083333 C-0.0811516153,4.73624988 -0.0811516153,5.26375012 0.244166667,5.58916667 L4.41083333,9.75583333 C4.73781574,10.0716435 5.25757065,10.067127 5.57901548,9.74568215 C5.90046031,9.42423731 5.90497684,8.90448241 5.58916667,8.5775 L2.01166667,5 L5.58916667,1.4225 C5.91448495,1.09708346 5.91448495,0.56958321 5.58916667,0.244166667 Z">
                                    </path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    &nbsp;
                    Back to Previous Page
                </button>
            </p>
        </StyledThankYou>
    )
}

export default ThankYou