import styled from 'styled-components'

export const StyledThankYou = styled.div`
    padding-top: 100px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
        margin-top: 72px;
        background: transparent;
        border: 0;
        font-weight: bold;
        cursor: pointer;
        &:focus {
            outline: 0;
        }
    }
    .success {
        margin-bottom: 24px;
    }
`